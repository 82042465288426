<template>
  <div class="container-top">
    <div class="pc">
      <lp-banner img="page_thumb_care_service.png" device="pc" title="런치팩 케어서비스" sub-title="서비스 유지/관리 플랜 안내"
        color="#242428" textAlign="left" :isDim="false" contentWidthPc="1152px" :height="320"></lp-banner>
      <div class="container">
        <div class="padding-top-80">
          <div class="flex-align">
            <div class="subtitle3 main">{{ text.title1 }}</div>
            <!--<div class="body4 error margin-left-8">*필수가입사항</div>-->
          </div>
          <div class="body2 sub2 margin-top-24 introduction-content">{{ text.content1 }}</div>

          <div class="flex-align" style="margin-top:80px">
            <div class="subtitle3 main">{{ text.title2 }}</div>
            <!-- <div class="body4 error margin-left-8">*vat별도</div> -->
          </div>
          <div class="body2 sub2 margin-top-24 introduction-content">{{ text.content2 }}</div>

          <div class="margin-top-24">
            <div class="card-wrapper">
              <div class="card-info" v-for="(list_item, idx) in list" :key="`hosting-${idx}`">
                <div class="body0-bold primary">{{ list_item.title }}</div>
                <div class="margin-top-24">
                  <div v-for="(item, item_idx) in list_item.items" :key="'item-' + item_idx" class="card-inner" :class="{
                    'margin-top-24': item_idx > 0
                  }">
                    <div class="flex-between" style="align-items: flex-start">
                      <div class="flex" style="align-items: flex-start; gap: 0 8px">
                        <div class="red-dot"></div>
                        <div class="body0-bold main">{{ item.name }}</div>
                      </div>
                      <div style="text-align: right">
                        <div class="body2-bold main">{{ item.price }}</div>
                        <div class="body5 sub2">{{ item.price_sub }}</div>
                      </div>
                    </div>
                    <ul class="margin-top-4">
                      <li v-for="(content_item, content_idx) in item.content" :key="'content-' + content_idx"
                        class="content-item body2 sub">{{ content_item }}</li>
                    </ul>
                  </div>
                </div>
                <!-- <div class="margin-top-8">
                  <div class="flex-align" v-if="item.price===0">
                    <span class="h5 main">{{ item.price | currencyNum }}</span>
                    <span class="body4 main margin-left-4">원</span>
                    <span class="body4 sub3">/</span>
                    <span class="body4 sub3 margin-left-4">월</span>
                  </div>
                  <div class="flex-align" v-else>
                    <span class="body4 main">충전금 최소</span>
                    <span class="h5 main margin-left-4 margin-right-4">{{ item.price | currencyNum }}</span>
                    <span class="body4 main">원 부터</span>
                  </div>
                </div>
                <div class="flex margin-top-28">
                  <div class="body2-bold sub">제공 내용</div>
                  <div class="body2 sub margin-left-16" style="white-space:pre-line">{{ item.content }}</div>
                </div> -->
              </div>
            </div>
            <div class="margin-top-24 body4 sub2 introduction-content">{{ text.star }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="mobile">
      <lp-banner img="page_thumb_care_service_m.png" device="mobile" title="런치팩 케어서비스" sub-title="서비스 유지/관리 플랜 안내"
        color="#242428" :isDim="false" :height="280" top="30%"></lp-banner>
      <div class="container">
        <div class="padding-top-32">
          <div class="flex-align">
            <div class="subtitle4 main">{{ text.title1 }}</div>
            <!--<div class="body6 error margin-left-8">*필수가입사항</div>-->
          </div>
          <div class="body4 sub2 margin-top-24 introduction-content">{{ text.content1 }}</div>

          <div class="flex-align margin-top-32">
            <div class="subtitle4 main">{{ text.title2 }}</div>
            <!-- <div class="body6 error margin-left-8">*vat별도</div> -->
          </div>
          <div class="body4 sub2 margin-top-24 introduction-content">
            {{ text.content2 }}</div>

          <div class="margin-top-32">
            <div>
              <div class="card-info" v-for="(list_item, idx) in list" :key="`hosting-${idx}`">
                <div class="h8 primary">{{ list_item.title }}</div>
                <div class="margin-top-20">
                  <div v-for="(item, item_idx) in list_item.items" :key="'item-' + item_idx" class="card-inner" :class="{
                    'margin-top-20': item_idx > 0
                  }">
                    <div class="flex-between" style="align-items: flex-start">
                      <div class="flex" style="align-items: flex-start; gap: 0 8px">
                        <div class="red-dot"></div>
                        <div class="h8 main" style="white-space: pre-line">{{ item.name }}</div>
                      </div>
                      <div style="text-align: right">
                        <div class="body3-bold main">{{ item.price }}</div>
                        <div class="body6 sub2">{{ item.price_sub }}</div>
                      </div>
                    </div>
                    <ul class="margin-top-4">
                      <li v-for="(content_item, content_idx) in item.content" :key="'content-' + content_idx"
                        class="content-item body5 sub">{{ content_item }}</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="margin-top-32 body6 sub2 introduction-content">{{ text.star }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LpBanner from "../component/LpBanner";
export default {
  name: "CareServiceIntro",
  components: { LpBanner },
  data() {
    return {
      list: [
        // {
        //   name: '기본',
        //   price: 0,
        //   content: '기본 서비스 보안, 유지보수\n' +
        //     '관리자 사이트 1:1 문의 서비스 제공'
        // },
        // {
        //   name: '케어서비스 10시간 ',
        //   price: 550000,
        //   content: '서비스 수정, 운영, 에러의 안정적인 해결\n' +
        //     ' 기획/개발/디자인 등의 추가 용역'
        // }
        {
          title: 'Track(문의 접수)',
          items: [
            {
              name: 'Basic',
              price: '솔루션 이용료',
              price_sub: '*상품별 상이',
              content: [
                '공식홈페이지 고객센터로 문의 접수',
                '서버, 결제 관련 이슈 문의 최대 72시간 내 답변',
                '프론트엔드 관련 이슈 문의 최대 7일 내 답변',
                '솔루션 정기 업데이트',
                '월1회 솔루션 개선 요청 창구의 문의를 수집하여 개발 여부 결정'
              ]
            },
            {
              name: 'Fast',
              price: '70,000원/월',
              price_sub: '*vat 별도',
              content: [
                '어드민 내 1:1 문의 창구 개설 및 담당자 매칭',
                '서버, 결제 관련 이슈 문의 최대 24시간 내 답변',
                '프론트엔드 관련 이슈 문의 최대 2일 내 답변',
                '솔루션 정기 업데이트 외 추가기능 즉시 적용 요청 가능',
                '솔루션 보완 기능 요청 시 실작업시간의 50%만 청구하여 진행'
              ]
            }
          ]
        },
        {
          title: 'Care(지원)',
          items: [
            {
              name: 'Care 4',
              price: '70,000원/월',
              price_sub: '*vat 별도',
              content: [
                '4시간의 지원/해결 보장 시간 제공',
                '1:1 문의 지원'
              ]
            },
            {
              name: 'Care 8',
              price: '150,000원/월',
              price_sub: '*vat 별도',
              content: [
                '8시간의 지원/해결 보장 시간 제공',
                '1:1 문의 지원'
              ]
            },
            {
              name: 'Care Ondemand',
              price: '500,000원/건',
              price_sub: '*선 충전금, vat 별도',
              content: [
                '선 충전 후 용역 발생 시마다 작업 시간 만큼의 금액 차감',
                '작업 종류에 따라 시간 당 5만~8만 원 책정'
              ]
            }
          ]
        },
      ],
      text: {
        title1: '케어서비스란?',
        title2: '케어서비스 요금제 상세 안내',
        content1: '런치팩에서는 개발팀이 없는 창업팀도 서비스 운영 과정에서 발생하는 모든 보수 사항들과 추가 개발에 대한 대응이 가능하도록\n' +
          '자체 유지/관리 서비스를 제공합니다.\n\n' +
          '외주가 아닌 런치팩 내부 직원들이 작업을 진행하기 때문에\n' +
          '커뮤니케이션이나 작업 퀄리티 부분에 있어 걱정하시는 이슈 없이, 원활한 비즈니스를 하실 수 있도록 돕고 있습니다.\n\n' +
          '런치팩의 유지관리 플랜은 Track(문의 접수)와 Care(지원)의 두 가지 종류로 분류되며,\n' +
          '각 항목 내 옵션들 중 하나씩 선택 및 조합하셔서 비즈니스 맞춤 유지관리 서비스를 합리적인 가격으로 이용하실 수 있습니다.\n\n' +
          '안정적인 풀 케어 서비스를 통해 유저 락인(Lock-In)을 높여 보세요!',
        content2: '이 요금제는 테마 상품(임대형 서비스) 이용 시에 최적화되어 있습니다.\n' +
          '테마 기반 부분수정 개발 범위 이상 스펙으로 구축된 서비스의 경우 담당자가 따로 안내하여 드립니다.\n' +
          '케어서비스 가입 및 변경을 원하시는 경우 어드민 내에서 결제해 주시거나, 문의창구를 통해 요청 내용을 남겨 주시면 1일 내 처리됩니다.',
        star: '*Track과 Care 내 각 옵션을 고객이 원하는 대로 선택하여 월 관리비를 책정합니다.\n' +
          '*케어서비스 결제 후 고객센터 또는 어드민 1:1 창구를 통해 필요하신 작업을 요청하시면 해당 사항에 대한 차감 시간 또는 차감 금액을 확인해 드리고 진행합니다.\n' +
          '*케어서비스는 고객의 서비스 운영에 필요한 사항이면 어떠한 요청에도 적용 가능합니다.\n' +
          '*Basic Track 이용 고객은 언제든지 Fast Track 신청이 가능합니다.\n' +
          '*미사용 시간은 다음 달로 이월되지 않습니다.\n' +
          '*Care4, Care8 플랜에서 보장하는 시간이 전부 사용되면 Care Ondemand 시행기준에 따라 지원될 추가 용역에 대해 과금합니다.\n' +
          '*Care 플랜으로 개발 진행 시 14일 이내 동일 작업 범위 안에서 동일 원인으로 인한 오류가 발생할 경우 해당 기능에 대한 무상 대응 서비스를 제공합니다.\n' +
          '(단, 작업 범위가 같더라도 오류 발생 요인이 다르면 다른 건으로 구분됩니다.)\n' +
          '*지속 작업일 수가 2일을 초과할 것으로 예상되는 요청의 경우 플랜 보장범위 외 별도 견적으로 작업을 진행할 수 있습니다.'
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', () => {
        this.setListItem();
      })
    })
  },
  methods: {
    setListItem() {
      let isPc = window.matchMedia('(min-width:1025px').matches;
      if (!isPc) {
        this.list[1].items[this.list[1].items.length - 1].name = 'Care\n' + 'Ondemand'
      }
    }
  }
}
</script>
<style lang="stylus" scoped>
  @import '~assets/css/lp_main'
  .pc
    .card-wrapper
      display grid
      grid-gap 24px
      grid-template-columns repeat(2, 1fr)
    .card-info
      border 1px solid $gray
      padding 32px 40px
      border-radius 8px
      .card-inner
        padding-bottom 24px
        border-bottom 1px solid $gray2
      .card-inner:last-child
        padding-bottom 0px
        border-bottom none
      .red-dot
        width 6px
        height 6px
        background-color $primary
      .content-item
        display flex
        align-items center
      .content-item::before
        content "·"
        margin-left -7px
        margin-right 4px
        font-size 32px

  .mobile
    .card-info
      border 1px solid $gray1
      padding 24px 20px
      border-radius 8px
      margin-top 20px
      .card-inner
        padding-bottom 20px
        border-bottom 1px solid $gray2
      .card-inner:last-child
        padding-bottom 0px
        border-bottom none
      .red-dot
        width 6px
        height 6px
        background-color $primary
      .content-item
        display flex
      .content-item::before
        content "·"
        margin-left -6px
        margin-right 4px
        font-size 28px
    .card-info:first-child
      margin-top 0px

  .card-issue-wrapper
    display grid
    grid-gap 24px
    grid-template-columns repeat(2, 1fr)

  .introduction-content
    white-space pre-line
</style>
